import React from 'react';
import { Link } from 'gatsby';
import productPlaceholder1 from '../assets/images/product-placeholder-01.jpg';
import productPlaceholder2 from '../assets/images/product-placeholder-02.jpg';
import productPlaceholder3 from '../assets/images/product-placeholder-03.jpg';

export default function FeaturedCheeses() {
  return (
    <>
      <div className="content-mw-1100">
        <h3>Featured Cheeses for November</h3>
      </div>
      <div className="three-col-grid content-mw-1100">
        <div className="card">
          <Link to="/cheeses/aherlow-blue">
            <img src={productPlaceholder1} alt="" />
          </Link>
          <p>
            <span>Aherlow Blue</span>
            <br />
            Raw cows milk blue veined cheese
          </p>
        </div>
        <div className="card">
          <Link to="/cheeses/coolmary">
            <img src={productPlaceholder2} alt="" />
          </Link>
          <p>
            <span>Coolmary</span>
            <br />
            Raw sheeps milk blue veined cheese
          </p>
        </div>
        <div className="card">
          <Link to="/cheeses/curra-with-garlic">
            <img src={productPlaceholder3} alt="" />
          </Link>
          <p>
            <span>Curra with Wild Garlic</span>
            <br />
            Pasteurised cows milk semi-hard cheese
          </p>
        </div>
      </div>
    </>
  );
}
