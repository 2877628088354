import React from 'react';
import { Link } from 'gatsby';

export default function HomeProducts() {
  return (
    <>
      <div
        className="home-products-group"
        style={{ padding: '40px', backgroundColor: 'lightgray' }}
      >
        <h3 className="content-mw-1100">Our 'Muskery' Range</h3>
        <div className="basic-grid content-mw-1100 home-range">
          <div className="card">
            <Link to="/cheeses/smoked-muskery">
              <img src="/images/products/14-sheep-smoked-muskry.jpg" alt="" />
            </Link>
            <p>Smoked Muskery</p>
          </div>
          <div className="card">
            <Link to="/cheeses/muskery-with-fenugreek-seeds">
              <img
                src="/images/products/13-sheep-muskry-with-fenugreek-seeds.jpg"
                alt=""
              />
            </Link>
            <p>Muskery with Fenugreek Seeds</p>
          </div>
          <div className="card">
            <Link to="/cheeses/muskery-with-nettles">
              <img
                src="images/products/25-sheep-muskry-with-nettles.jpg"
                alt=""
              />
            </Link>
            <p>Muskery with Nettles</p>
          </div>
          <div className="card">
            <Link to="/cheeses/muskery-with-wild-garlic">
              <img
                src="/images/products/12-sheep-muskry-with-wild-garlic.jpg"
                alt=""
              />
            </Link>
            <p>Muskery with Wild Garlic</p>
          </div>
        </div>
      </div>
    </>
  );
}
