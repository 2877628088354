import React from 'react';
import ourProcess1 from '../assets/images/our-process-01.jpg';
import ourProcess2 from '../assets/images/glen-of-aherlow.jpg';
import ourProcess3 from '../assets/images/our-process-03.jpg';

export default function AboutUs() {
  return (
    <>
      <h3 className="content-mw-1100" id="about-us">
        About Us{' '}
      </h3>
      <div className="three-col-grid grid-no-scale content-mw-1100">
        <div className="card">
          <img src={ourProcess1} alt="" />
        </div>
        <div className="card">
          <img src={ourProcess2} alt="" />
        </div>
        <div className="card">
          <img src={ourProcess3} alt="" />
        </div>
      </div>
      <p className="content-mw-1100 process-desc">
        The Blues Creamery is located in Newtown, Glen Of Aherlow, County
        Tipperary. It specialises in handcrafted artisan cheeses from cows,
        goats and sheep which can be hard, semi hard, smoked, flavoured, semi
        soft and/or blue veined made from Tipperary grass fed animals during the
        period March to October with a low carbon footprint.
        <br />
        <br />A variety of cheeses are also custom made for some of our
        customers at farmers markets, restaurants, and speciality retail in
        Ireland, the UK and France. The Blues Creamery is unique with its cheese
        product offering diversity, friendly engagement with its customer base
        and it's commitment with its customer base to a sustainable milk price
        for its local milk producers.
      </p>
    </>
  );
}
