import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import heroBg from '../assets/images/hero-cheese-selection-bg.jpg';
import grungeBorder from '../assets/images/hero-grunge-border.png';

const HeroStyles = styled.div`
  background: url(${grungeBorder}), url(${heroBg}) rgba(0, 0, 0, 0.7);
  min-height: 400px;
  background-size: auto, cover;
  background-position: bottom center, center center;
  background-repeat: no-repeat;
  color: white;
  vertical-align: middle;
  display: flexbox;
  align-items: center;
  grid-template-rows: 1fr 1fr 1fr;

  a {
    display: inline-block;
    background-color: #015cab;
    color: white;
    border-radius: 4px;
    padding: 15px;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 10px;
    font-size: 1.8rem;
    font-family: BrandFont;
  }

  div {
    text-align: left;
    max-width: 1100px;
    width: 100%;
  }

  h2 {
    font-family: BrandFont;
    font-size: 6rem;
    margin-bottom: 0px;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: inline-block;
  }
  p {
    font-family: AlataRegular;
    margin: 0;
    font-style: italic;
  }
`;

export default function HomeHero() {
  return (
    <>
      <HeroStyles>
        <div className="content-mw-1100">
          <h2>A TASTE OF THE LAND</h2>
          <p>Artisan Produce from The Glen of Aherlow, Tipperary</p>
          <Link to="/cheeses">Browse our online store</Link>
        </div>
      </HeroStyles>
    </>
  );
}
