import React from 'react';
import styled from 'styled-components';
import HomeHero from '../components/HomeHero';
import Testimonials from '../components/Testimonials';
import FeaturedCheeses from '../components/FeaturedCheeses';
import headerChevronBg from '../assets/images/header-chevron-bg.png';
import chevronGrungeBorderBottom from '../assets/images/chevron-grunge-border-bottom.png';
import chevronGrungeBorderTop from '../assets/images/chevron-grunge-border-top.png';
import OurProcess from '../components/OurProcess';
import OurSuppliers from '../components/OurSuppliers';
import HomeProducts from '../components/HomeProducts';
import SEO from '../components/SEO';

const ChevronBlock = styled.div`
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: url(${chevronGrungeBorderBottom}), url(${chevronGrungeBorderTop}),
    url(${headerChevronBg});
  background-position: bottom center, top center, center center;
  color: white;
  /* background-repeat: no-repeat; */
  background-size: cover;

  .slick-dots button {
    --cast: none;
  }

  > div {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 35px;
    font-size: 1.8rem;
    line-height: 150%;
    padding-bottom: 25px;
  }

  img {
    max-width: 300px;
    @media (max-width: 680px) {
      /* display:none; */
    }
  }
`;

export default function Homepage() {
  return (
    <>
      <SEO title="Welcome to Blues Creamery - A Taste of the land" />
      <HomeHero />
      <FeaturedCheeses />
      <ChevronBlock>
        <div className="content-mw-980">
          <Testimonials />
        </div>
      </ChevronBlock>
      <OurProcess />
      <HomeProducts />
      <OurSuppliers />
    </>
  );
}
