import React, { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      arrows: false,
    };

    const testimonials = [
      {
        name: 'Kevin @ Loose Canon, Drury St Dublin',
        quote:
          "We love the variation of the Blues Creamery. They're innovative, interesting and always delicious. Having any of their range in Loose Canon always brings something new and fascinating to the cheese counter!",
      },
      {
        name: "Stéphanie Mariaud, l'Art de la Fromagerie",
        quote:
          '...An addiction for top quality products. A little magic and a lot of work later from Blues Creamery and here there are, top cheeses in our shops in Marseilles!',
      },
    ];

    return (
      <>
        <div
          style={{ maxWidth: '980px' }}
          className="slide-container testimonials-container"
        >
          <div
            style={{
              maxWidth: '980px',
              margin: '0 auto',
            }}
          >
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <div
                  className="intro-para"
                  style={{ padding: '20px !important' }}
                >
                  "{testimonial.quote}"<span>{testimonial.name}</span>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </>
    );
  }
}
