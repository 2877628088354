import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import supplier1 from '../assets/images/supplier-03.jpg';
import supplier2 from '../assets/images/supplier-02.jpg';
import supplier3 from '../assets/images/supplier-goat.jpg';

const Supplier = styled.div`
  p {
    font-size: 1.7rem;
    line-height: 130%;
    margin-top: 10px;
    a {
      display: block;
      margin-top: 10px;
      color: var(--blue);
    }
  }
  span {
    color: #333;
    margin-bottom: 10px;
    display: block;
    font-size: 1.8rem;
  }
`;

export default function OurSuppliers() {
  return (
    <>
      <div className="content-mw-1100 our-suppliers">
        <h3>Ingredients sourced from trusted local suppliers</h3>
      </div>
      <div className="three-col-grid grid-no-scale content-mw-1100">
        <Supplier>
          <Link to="/suppliers">
            <img src={supplier2} alt="" />
          </Link>
          <p>
            <span>Richard & Bernie Donovan</span>
            Quality cow's milk sourced from Richard & Bernie who farm is in
            Bohercarron Emly, Tipperary.
            <Link to="/suppliers">Read more &raquo;</Link>
          </p>
        </Supplier>
        <Supplier>
          <Link to="/suppliers">
            <img src={supplier1} alt="" />
          </Link>
          <p>
            <span>Barry & Lorraine Cahalan</span>
            Our quality sheep's milk is supplied by sheep milk farmers Barry &
            Lorraine Cahalan from Terryglass, Tipperary.
            <Link to="/suppliers">Read more &raquo;</Link>
          </p>
        </Supplier>
        <Supplier>
          <Link to="/suppliers">
            <img src={supplier3} alt="" />
          </Link>
          <p>
            <span>Michael & Anne Mc Gelligit</span>
            Our Goats milk is supplied by Michael and Anne Mc Gelligit whose
            family farm is based near Cahir County Tipperary.
            <Link to="/suppliers">Read more &raquo;</Link>
          </p>
        </Supplier>
      </div>
    </>
  );
}
